exports.components = {
  "component---src-pages-imprint-js": () => import("./../../../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-templates-article-index-tsx": () => import("./../../../src/templates/Article/index.tsx" /* webpackChunkName: "component---src-templates-article-index-tsx" */),
  "component---src-templates-article-listing-index-tsx": () => import("./../../../src/templates/ArticleListing/index.tsx" /* webpackChunkName: "component---src-templates-article-listing-index-tsx" */),
  "component---src-templates-company-index-tsx": () => import("./../../../src/templates/Company/index.tsx" /* webpackChunkName: "component---src-templates-company-index-tsx" */),
  "component---src-templates-company-listing-index-tsx": () => import("./../../../src/templates/CompanyListing/index.tsx" /* webpackChunkName: "component---src-templates-company-listing-index-tsx" */)
}

